import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import SiteFooter from './SiteFooter'

function HomeFooter() {
    return (
        <SiteFooter className="footer-wrap-layout1">
            <section className="intro pb--50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <p> Es ist allgemein anerkannt, dass die Jugend von heute die Zukunft von morgen bildet. Aber was für eine Zukunft soll ihnen eigentlich überlassen werden und sehen wir die Jugend tatsächlich schon als aktive Akteure die das Potenzial haben, ihr Leben und das Leben der Gesellschaft, maßgeblich zu gestalten? Tatsächlich wird der Jugend oft anderes vorgehalten - sie haben keine lange Aufmerksamkeitsspanne, sind leicht ablenkbar, suchen nur Spaß und haben kein Interesse am Wohlergehen anderer. Aktuell gibt es jedoch immer mehr Beispiele, die offenkundig zeigen, dass Jugendliche sehr wohl ein starkes Interesse daran haben sich aktiv für ihre Welt einzusetzen und Haltung beziehen. Doch wie ernst werden diese Stimmen tatsächlich genommen? Was führt dazu, dass Jugendliche sich ermächtigt fühlen ihre Stimme zu erheben und wie kann der Rest der Gesellschaft sie weiter bestärken und bereits jetzt aktiv in Entscheidungsprozesse mit einbeziehen? Was für ein Umfeld und welche Haltungen tragen dazu bei, dass die Stimmen Jugendlicher nicht nur als vorübergehender Trend wahrgenommen und bejubelt wird, sondern tatsächlich auf Augenhöhe mit in Betracht gezogen werden?</p>
                            <p>Dieser Blog hat die Absicht, die Rolle und das Potential der Jugend aus unterschiedlichen Perspektiven zu beleuchten und eine Plattform zu schaffen, in der Annahmen und Umfelder analysiert werden, die es der Gesellschaft (Jugendliche selbst mit eingeschlossen) ermöglichen, das Potenzial junger Menschen freizusetzen.  Hier werden Beiträge aus Veranstaltungen, aktuelle Entwicklungen zum Thema Jugendbeteiligung, Gedanken von jungen Menschen, Experten, interessierten Organisationen gepostet. Hoffnung ist damit weitere Perspektiven, Ansätze und Erfahrungen zum Thema “Jugend gestaltet Zukunft mit allen!” in den Gesamtgesellschaftlichen Diskurs mit einzubringen.</p>
                        </div>
                        <div className="col-lg-5">
                            <StaticQuery query={graphql`
                                    query {
                                        file(relativePath: { eq: "homepage.jpg" }) {
                                            childImageSharp {
                                                fluid(maxWidth: 1000) {
                                                    ...GatsbyImageSharpFluid_noBase64
                                                }
                                            }
                                        }
                                    }
                                `} render={data=> (
                                    <Img fluid={data.file.childImageSharp.fluid} />
                                )} />
                        </div>
                    </div>
                </div>
            </section>
        </SiteFooter>
    )
}

export default HomeFooter