import React from 'react'

import Wrapper from '../components/Wrapper'
import HomeHeader from '../components/HomeHeader'
import HomeFooter from '../components/HomeFooter'

import HomepagePosts from '../components/HomepagePosts'

function Homepage() {
    return (
        <Wrapper title="Home">
            <HomeHeader />
            <div className="box-layout-child bg-white">
                <HomepagePosts />
            </div>
            <HomeFooter />
        </Wrapper>
    )
}

export default Homepage