import React from 'react'
import { Link } from 'gatsby'

import * as logo from '../images/logo.png'
import * as identity from '../images/identity.png'
import Navigation from './Navigation';

function HomeHeader({ data }) {
    return (
        <header>
            <div id="header-topbar" className="pt--14 pb--14 mb-3 bg--light border-bootom border-color-accent1">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-4 d-none d-lg-flex justify-content-start">
                            <div className="logo-area">
                                <Link to="/" className="temp-logo" id="temp-logo">
                                    <img src={identity} alt="" className="img-fluid logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-6 d-flex justify-content-sm-start justify-content-lg-center">
                            <div className="logo-area">
                                <Link to="/" className="temp-logo" id="temp-logo">
                                    <img src={logo} alt="Jugend gestaltet Zukunft mit allen" className="img-fluid logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-6 d-flex justify-content-end">
                            <Navigation />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HomeHeader