import React, { Fragment } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

export default function HomepagePosts() {
  return (
    <StaticQuery
      query={graphql`
        query {
          latestPost: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: 1
          ) {
            edges {
              node {
                frontmatter {
                  title
                  date(formatString: "DD. MMMM YYYY", locale: "de")
                  feature_image {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 80) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
                excerpt(pruneLength: 70, format: PLAIN)
                fields {
                  slug
                }
              }
            }
          }
          secondRowPosts: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: 3
            skip: 1
          ) {
            edges {
              node {
                frontmatter {
                  title
                  date(formatString: "DD. MMMM YYYY", locale: "de")
                  feature_image {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 80) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
                excerpt(pruneLength: 70, format: PLAIN)
                fields {
                  slug
                }
              }
            }
          }
          thirdRowPost: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: 1
            skip: 4
          ) {
            edges {
              node {
                frontmatter {
                  title
                  date(formatString: "DD. MMMM YYYY", locale: "de")
                  feature_image {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 80) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
                excerpt(pruneLength: 70, format: PLAIN)
                fields {
                  slug
                }
              }
            }
          }
          fourthRowPost: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: 1
            skip: 5
          ) {
            edges {
              node {
                frontmatter {
                  title
                  date(formatString: "DD. MMMM YYYY", locale: "de")
                  feature_image {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 80) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
                excerpt(pruneLength: 70, format: PLAIN)
                fields {
                  slug
                }
              }
            }
          }
          sidebarPosts: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: 3
            skip: 6
          ) {
            edges {
              node {
                frontmatter {
                  title
                  date(formatString: "DD. MMMM YYYY", locale: "de")
                  feature_image {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 80) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
                excerpt(pruneLength: 70, format: PLAIN)
                fields {
                  slug
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Fragment>
          {/* Latest Post */}
          {data.latestPost.edges.length ? (
            <section className="slider-wrap-layout1">
              <div className="container">
                <div
                  className={
                    !data.latestPost.edges[0].node.frontmatter.feature_image
                      ? 'slider-box-layout2'
                      : 'slider-box-layout1'
                  }
                >
                  {data.latestPost.edges[0].node.frontmatter.feature_image && (
                    <div className="item-img">
                      <Img
                        fluid={
                          data.latestPost.edges[0].node.frontmatter
                            .feature_image.childImageSharp.fluid
                        }
                        alt=""
                      />
                    </div>
                  )}
                  <div className="item-content">
                    <ul className="entry-meta meta-color-dark">
                      <li>
                        <i className="fas fa-calendar-alt"></i>
                        {data.latestPost.edges[0].node.frontmatter.date}
                      </li>
                    </ul>
                    <h2 className="item-title">
                      <Link
                        to={`/posts${data.latestPost.edges[0].node.fields.slug}`}
                      >
                        {data.latestPost.edges[0].node.frontmatter.title}
                      </Link>
                    </h2>
                    <p className="item-description">
                      {data.latestPost.edges[0].node.excerpt}
                    </p>
                  </div>
                </div>
              </div>
            </section>
          ) : null}

          {/* Second Row Posts */}
          {data.secondRowPosts.edges.length ? (
            <section className="blog-wrap-layout1">
              <div className="container">
                <div className="row">
                  {data.secondRowPosts.edges.map((post, i) => (
                    <div className="col-lg-4" key={i}>
                      <div
                        className={
                          !post.node.frontmatter.feature_image
                            ? 'blog-box-layout-no-image'
                            : 'blog-box-layout1'
                        }
                      >
                        {post.node.frontmatter.feature_image && (
                          <div className="item-img">
                            <Link to={`/posts${post.node.fields.slug}`}>
                              <Img
                                fluid={
                                  post.node.frontmatter.feature_image
                                    .childImageSharp.fluid
                                }
                                alt=""
                              />
                            </Link>
                          </div>
                        )}
                        <div className="item-content">
                          <ul className="entry-meta meta-color-dark">
                            <li>
                              <i className="fas fa-calendar-alt"></i>
                              {post.node.frontmatter.date}
                            </li>
                          </ul>
                          <h3 className="item-title">
                            <Link to={`/posts${post.node.fields.slug}`}>
                              {post.node.frontmatter.title}
                            </Link>
                          </h3>
                          <p className="item-description">
                            {post.node.excerpt}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          ) : null}

          <section className="blog-wrap-layout1">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  {/* Third Row Post */}
                  {data.thirdRowPost.edges.length ? (
                    <div className="blog-box-layout1">
                      {data.thirdRowPost.edges[0].node.frontmatter
                        .feature_image && (
                        <div className="item-img">
                          <Link
                            to={`/posts${data.thirdRowPost.edges[0].node.fields.slug}`}
                          >
                            <Img
                              fluid={
                                data.thirdRowPost.edges[0].node.frontmatter
                                  .feature_image.childImageSharp.fluid
                              }
                              alt=""
                            />
                          </Link>
                        </div>
                      )}
                      <div className="item-content">
                        <ul className="entry-meta meta-color-dark">
                          <li>
                            <i className="fas fa-calendar-alt"></i>
                            {data.thirdRowPost.edges[0].node.frontmatter.date}
                          </li>
                        </ul>
                        <h3 className="item-title">
                          <Link
                            to={`/posts${data.thirdRowPost.edges[0].node.fields.slug}`}
                          >
                            {data.thirdRowPost.edges[0].node.frontmatter.title}
                          </Link>
                        </h3>
                        <p className="item-description">
                          {data.thirdRowPost.edges[0].node.excerpt}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {/* Fourth Row Post */}
                  {data.fourthRowPost.edges.length ? (
                    <div className="blog-box-layout1">
                      {data.fourthRowPost.edges[0].node.frontmatter
                        .feature_image && (
                        <div className="item-img">
                          <Link
                            to={`/posts${data.fourthRowPost.edges[0].node.fields.slug}`}
                          >
                            <Img
                              fluid={
                                data.fourthRowPost.edges[0].node.frontmatter
                                  .feature_image.childImageSharp.fluid
                              }
                              alt=""
                            />
                          </Link>
                        </div>
                      )}
                      <div className="item-content">
                        <ul className="entry-meta meta-color-dark">
                          <li>
                            <i className="fas fa-calendar-alt"></i>
                            {data.fourthRowPost.edges[0].node.frontmatter.date}
                          </li>
                        </ul>
                        <h3 className="item-title">
                          <Link
                            to={`/posts${data.fourthRowPost.edges[0].node.fields.slug}`}
                          >
                            {data.fourthRowPost.edges[0].node.frontmatter.title}
                          </Link>
                        </h3>
                        <p className="item-description">
                          {data.fourthRowPost.edges[0].node.excerpt}
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>

                {/* Sidebar Posts */}
                {data.sidebarPosts.edges.length ? (
                  <div className="col-lg-4">
                    {data.sidebarPosts.edges.map((post, i) => (
                      <div className="blog-box-layout1" key={i}>
                        {post.node.frontmatter.feature_image && (
                          <div className="item-img">
                            <Link to={`/posts${post.node.fields.slug}`}>
                              <Img
                                fluid={
                                  post.node.frontmatter.feature_image
                                    .childImageSharp.fluid
                                }
                                alt=""
                              />
                            </Link>
                          </div>
                        )}
                        <div className="item-content">
                          <ul className="entry-meta meta-color-dark">
                            <li>
                              <i className="fas fa-calendar-alt"></i>
                              {post.node.frontmatter.date}
                            </li>
                          </ul>
                          <h3 className="item-title">
                            <Link to={`/posts${post.node.fields.slug}`}>
                              {post.node.frontmatter.title}
                            </Link>
                          </h3>
                          <p className="item-description">
                            {post.node.excerpt}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </section>

          <div className="container d-flex justify-content-end my-5">
            <Link to="/archiv/">Weitere Artikel »</Link>
          </div>
        </Fragment>
      )}
    />
  )
}
